.my-collections-page-content__component .header-block {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .my-collections-page-content__component .header-block .text-block {
    margin-right: 20px;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
    .my-collections-page-content__component .header-block .text-block .text {
      font-family: "Poppins", sans-serif;
      color: #777E90;
      line-height: 1.6; }
    @media only screen and (max-width: 768px) {
      .my-collections-page-content__component .header-block .text-block {
        width: 100%;
        margin-bottom: 20px; } }
  @media only screen and (max-width: 768px) {
    .my-collections-page-content__component .header-block {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; } }
