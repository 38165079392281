.common-table-cell__component {
  display: table-cell;
  padding: 12px 0px 12px 12px;
  border-bottom: 1px solid #E6E8EC;
  font-size: 12px;
  font-weight: 400; }
  .common-table-cell__component:last-child {
    padding-right: 12px; }
  .common-table-cell__component .parameter {
    display: none; }
