.top-menu__component {
  min-height: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  @media only screen and (max-width: 400px) {
    .top-menu__component .logo-container .main-logo__component .heylink-logo {
      margin-right: 10px; }
    .top-menu__component .logo-container .main-logo__component .text-logo {
      display: none; } }
  .top-menu__component .search-container {
    padding: 0 20px;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
    @media only screen and (max-width: 992px) {
      .top-menu__component .search-container {
        display: none; } }
  .top-menu__component .nav-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .top-menu__component .nav-container .nav-item {
      margin-right: 16px;
      color: #777E90;
      font-weight: 500;
      white-space: nowrap; }
      .top-menu__component .nav-container .nav-item:last-child {
        margin-right: 0; }
      .top-menu__component .nav-container .nav-item.signup {
        margin-right: 10px; }
      .top-menu__component .nav-container .nav-item:hover {
        color: #23262F; }
    .top-menu__component .nav-container .top-menu-my-account__component {
      margin-left: 10px; }
    @media only screen and (max-width: 992px) {
      .top-menu__component .nav-container {
        display: none; } }
  .top-menu__component .mobile-menu-icon {
    display: none; }
    @media only screen and (max-width: 992px) {
      .top-menu__component .mobile-menu-icon {
        display: block; } }
