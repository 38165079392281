.global-search__component {
  width: 100%;
  position: relative; }
  .global-search__component .input-container {
    width: 100%; }
    .global-search__component .input-container .ui.input {
      width: 100%; }
      .global-search__component .input-container .ui.input input {
        width: 100%;
        height: 45px;
        padding-left: 20px !important;
        padding-right: 40px !important;
        -webkit-border-radius: 12px !important;
        -moz-border-radius: 12px !important;
        -ms-border-radius: 12px !important;
        border-radius: 12px !important;
        border: 1px solid #E6E8EC;
        font-family: "Poppins", sans-serif; }
      .global-search__component .input-container .ui.input input::-moz-placeholder {
        color: #c1c1c2; }
      .global-search__component .input-container .ui.input input::-webkit-input-placeholder {
        color: #c1c1c2; }
      .global-search__component .input-container .ui.input input:-ms-input-placeholder {
        color: #c1c1c2; }
      .global-search__component .input-container .ui.input input::-ms-input-placeholder {
        color: #c1c1c2; }
      .global-search__component .input-container .ui.input input::placeholder {
        color: #c1c1c2; }
      .global-search__component .input-container .ui.input input:-webkit-autofill,
      .global-search__component .input-container .ui.input input:-webkit-autofill:hover,
      .global-search__component .input-container .ui.input input:-webkit-autofill:focus,
      .global-search__component .input-container .ui.input input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #E6E8EC inset !important; }
  .global-search__component .popup-container {
    width: 100%;
    max-height: 300px;
    padding: 16px;
    overflow-y: auto;
    background: #FFFFFF;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    border-radius: 12px;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    border: 1px solid #E6E8EC;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 3; }
    .global-search__component .popup-container .section {
      margin-bottom: 10px; }
      .global-search__component .popup-container .section:last-child {
        margin-bottom: 0; }
      .global-search__component .popup-container .section .section-title {
        font-size: 12px;
        font-weight: 500;
        color: #777E90; }
      .global-search__component .popup-container .section .link-item {
        padding: 10px;
        display: block;
        font-size: 12px;
        color: #23262F;
        cursor: pointer;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        border-radius: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .global-search__component .popup-container .section .link-item:hover {
          background: #F4F5F6;
          font-weight: 500; }
