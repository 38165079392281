.ui.dropdown.common-dropdown__component {
  width: 100%;
  height: 45px;
  border: 2px solid #F4F5F6;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px; }
  .ui.dropdown.common-dropdown__component > .text {
    width: 100%;
    padding-right: 20px;
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    font-family: "Poppins", sans-serif; }
  .ui.dropdown.common-dropdown__component > .icon {
    top: 12.5px !important; }
  .ui.dropdown.common-dropdown__component .menu {
    width: calc(100% + 2px);
    min-width: calc(100% + 2px);
    margin-top: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #FFFFFF;
    border: 1px solid #E6E8EC; }
    .ui.dropdown.common-dropdown__component .menu .item {
      border-top: none; }
      .ui.dropdown.common-dropdown__component .menu .item .text {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: "Poppins", sans-serif; }
