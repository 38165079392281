.contact-page-content__component {
  margin-bottom: 30px; }
  .contact-page-content__component .team-image-container {
    margin-bottom: 50px; }
    .contact-page-content__component .team-image-container img {
      width: 100%;
      max-width: 640px;
      margin: 0 auto;
      display: block; }
  .contact-page-content__component .get-in-touch-section__component {
    margin: 0 auto 40px auto; }
  .contact-page-content__component .contact-points__component {
    margin: 0 auto 20px auto; }
