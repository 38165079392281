.asset-owner-actions__component {
  padding: 16px;
  background: #F4F5F6;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  border-radius: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }
  .asset-owner-actions__component .ui.button.button__component {
    width: 170px; }
    .asset-owner-actions__component .ui.button.button__component:first-child {
      margin-right: 5px; }
      @media only screen and (max-width: 450px) {
        .asset-owner-actions__component .ui.button.button__component:first-child {
          margin-bottom: 10px;
          margin-right: 0; } }
    @media only screen and (max-width: 450px) {
      .asset-owner-actions__component .ui.button.button__component {
        width: 100%;
        margin: 0; } }
  @media only screen and (max-width: 450px) {
    .asset-owner-actions__component {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; } }
