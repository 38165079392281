.ui.modal.connect-hedera-account-modal__component .content .connection-step .info-text {
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  color: #777E90;
  line-height: 1.6;
  text-align: center; }

.ui.modal.connect-hedera-account-modal__component .content .connection-step .ui.button.button__component {
  margin: 0 auto; }

.ui.modal.connect-hedera-account-modal__component .content .connection-step .common-message__component {
  margin-top: 20px; }

.ui.modal.connect-hedera-account-modal__component .content .creation-step .phrase-info {
  margin-bottom: 30px;
  padding: 16px;
  border: 2px solid #FF385C;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px; }
  .ui.modal.connect-hedera-account-modal__component .content .creation-step .phrase-info .title {
    margin-bottom: 10px;
    color: #FF385C;
    font-size: 20px;
    font-weight: 500;
    text-align: center; }
  .ui.modal.connect-hedera-account-modal__component .content .creation-step .phrase-info .info-text {
    margin-bottom: 10px; }
    .ui.modal.connect-hedera-account-modal__component .content .creation-step .phrase-info .info-text span {
      font-weight: 600; }
  .ui.modal.connect-hedera-account-modal__component .content .creation-step .phrase-info .phrase-container {
    padding: 10px;
    border: 2px solid #E6E8EC;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    border-radius: 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap; }
    .ui.modal.connect-hedera-account-modal__component .content .creation-step .phrase-info .phrase-container .word-item {
      padding: 5px 10px; }

.ui.modal.connect-hedera-account-modal__component .content .creation-step .checkbox-container {
  margin-bottom: 30px; }

.ui.modal.connect-hedera-account-modal__component .content .creation-step .ui.button.button__component {
  margin: 0 auto; }

.ui.modal.connect-hedera-account-modal__component .content .success-step .undertext {
  margin-bottom: 20px;
  font-size: 16px; }

.ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper {
  margin-bottom: 20px; }
  .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .title-block {
    margin-bottom: 16px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .title-block .icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-right: 16px;
      background-image: url("icons/ic_hedera_icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
    .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .title-block .title {
      font-weight: 500;
      font-size: 16px; }
  .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .parameter {
    margin-bottom: 20px; }
    .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .parameter .label {
      margin-bottom: 5px;
      font-weight: 600; }
    .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .parameter .value {
      word-wrap: break-word; }
  .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .phrase-info {
    padding: 16px;
    border: 2px solid #FF385C;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    border-radius: 16px; }
    .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .phrase-info .title {
      margin-bottom: 10px;
      color: #FF385C;
      font-size: 20px;
      font-weight: 500;
      text-align: center; }
    .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .phrase-info .info-text {
      margin-bottom: 10px; }
      .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .phrase-info .info-text span {
        font-weight: 600; }
    .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .phrase-info .phrase-container {
      padding: 10px;
      border: 2px solid #E6E8EC;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      border-radius: 12px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; }
      .ui.modal.connect-hedera-account-modal__component .content .success-step .info-wrapper .phrase-info .phrase-container .word-item {
        padding: 5px 10px; }

.ui.modal.connect-hedera-account-modal__component .content .success-step .attention-text {
  margin-bottom: 20px;
  text-align: center;
  color: #FF385C;
  font-size: 16px; }
  .ui.modal.connect-hedera-account-modal__component .content .success-step .attention-text span {
    font-weight: 600; }

.ui.modal.connect-hedera-account-modal__component .content .success-step .ui.button.button__component {
  width: 250px;
  margin: 0 auto; }
