.upload-asset-media__component .title {
  margin-bottom: 16px;
  font-family: "Poppins", sans-serif;
  color: #23262F;
  font-size: 18px;
  font-weight: 500; }

.upload-asset-media__component .undertext {
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  color: #777E90;
  line-height: 1.6;
  font-size: 12px; }

.upload-asset-media__component .dropzone-container {
  width: 350px;
  height: 350px;
  padding: 5px;
  border: 2px dashed #E6E8EC;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
  overflow: hidden; }
  .upload-asset-media__component .dropzone-container .dropzone-area {
    width: 100%;
    height: 100%;
    background: #F4F5F6;
    cursor: pointer;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    border-radius: 16px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .upload-asset-media__component .dropzone-container .dropzone-area .image-block {
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .upload-asset-media__component .dropzone-container .dropzone-area .image-block img {
        max-width: 100%;
        display: block; }
    .upload-asset-media__component .dropzone-container .dropzone-area .info-block .icon {
      width: 48px;
      height: 48px;
      margin: 0 auto 10px auto;
      background-image: url("icons/ic_banner.svg");
      background-size: contain; }
    .upload-asset-media__component .dropzone-container .dropzone-area .info-block .text {
      text-align: center;
      font-size: 12px;
      color: #777E90; }
  @media only screen and (max-width: 450px) {
    .upload-asset-media__component .dropzone-container {
      width: 100%;
      min-height: 300px; } }

.upload-asset-media__component .remove-button {
  margin-top: 10px;
  font-size: 12px;
  color: #B1B5C3; }
  .upload-asset-media__component .remove-button:hover {
    color: #23262F;
    font-weight: 500;
    cursor: pointer; }
