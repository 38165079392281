.ui.modal.asset-buy-modal__component .content .checkout-form .parameter {
  height: 60px;
  border-bottom: 1px solid #F4F5F6;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .ui.modal.asset-buy-modal__component .content .checkout-form .parameter:first-child {
    border-top: 1px solid #F4F5F6; }
  .ui.modal.asset-buy-modal__component .content .checkout-form .parameter .label {
    color: #777E90; }
  .ui.modal.asset-buy-modal__component .content .checkout-form .parameter .value {
    font-weight: 500; }

.ui.modal.asset-buy-modal__component .content .checkout-form .common-message__component {
  margin-top: 20px; }

.ui.modal.asset-buy-modal__component .content .checkout-form .ui.button.button__component {
  margin: 30px auto 0 auto; }

.ui.modal.asset-buy-modal__component .content .success-checkout-wrapper .icon {
  width: 90px;
  height: 90px;
  margin: 0 auto 20px auto;
  background-image: url("./icons/ic_success_payment.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

.ui.modal.asset-buy-modal__component .content .success-checkout-wrapper .success-text {
  font-size: 16px;
  font-weight: 500;
  text-align: center; }

.ui.modal.asset-buy-modal__component .content .success-checkout-wrapper .ui.button.button__component {
  margin: 30px auto 0 auto; }
