.common-pagination__component {
  width: 100%;
  padding-inline-start: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .common-pagination__component li {
    margin: 0 5px;
    text-align: center;
    background: transparent;
    list-style-type: none;
    overflow: hidden;
    cursor: pointer;
    color: #777E90;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
    .common-pagination__component li.previous, .common-pagination__component li.next {
      font-size: 16px; }
    .common-pagination__component li.active {
      font-weight: 500;
      color: #23262F;
      background: #F4F5F6; }
    .common-pagination__component li a {
      width: 35px;
      line-height: 35px;
      display: block;
      outline: none;
      color: inherit; }
