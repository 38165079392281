.common-tab-item__component {
  padding: 4px 16px;
  color: #23262F;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px; }
  .common-tab-item__component:last-child {
    margin-right: 0; }
  .common-tab-item__component.active {
    background: #E6E8EC; }
  .common-tab-item__component:hover {
    color: #23262F; }
