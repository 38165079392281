.asset-price-info__component .price {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #00CE67; }
  .asset-price-info__component .price.not-listed {
    font-weight: 300;
    color: #B1B5C3; }

.asset-price-info__component .buttons-block {
  margin-top: 20px; }
  .asset-price-info__component .buttons-block .ui.button.button__component {
    width: 100%;
    max-width: 350px; }
    .asset-price-info__component .buttons-block .ui.button.button__component:first-child {
      margin-bottom: 10px; }
