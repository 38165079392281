.network-error__component {
  width: 100%;
  height: 100vh;
  padding: 20px;
  background: #FFFFFF; }
  .network-error__component .image-wrapper {
    width: 100%;
    max-width: 800px;
    height: 500px;
    margin: 0 auto 50px auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./images/502_error_(HeyLink).svg"); }
    @media only screen and (max-width: 550px) {
      .network-error__component .image-wrapper {
        max-width: 600px;
        height: 250px;
        margin: 0 auto 30px auto; } }
  .network-error__component .description {
    margin-bottom: 50px;
    line-height: 1.3;
    font-size: 34px;
    font-weight: 600;
    text-align: center;
    color: #393aa7; }
    @media only screen and (max-width: 550px) {
      .network-error__component .description {
        margin-bottom: 30px;
        font-size: 20px; } }
  .network-error__component .footer-text {
    text-align: center;
    color: #393aa7;
    font-size: 18px;
    font-weight: 600; }
    .network-error__component .footer-text a {
      color: #3772FF; }
    @media only screen and (max-width: 550px) {
      .network-error__component .footer-text {
        font-size: 16px; } }
