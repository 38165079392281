// Fonts
$poppins-font: 'Poppins', sans-serif;
$dm-sans-font: 'DM Sans', sans-serif;

// Common Colors
$white: #FFFFFF;

// Light Mode Colors
$primary-1: #FF385C;
$primary-2: #9757D7;
$primary-3: #00CE67;
$primary-4: #3772FF;

$secondary-1: #4BC9F0;
$secondary-2: #FFBB37;
$secondary-3: #00D6D4;
$secondary-4: #E4D7CF;

$neutrals-1: #141416;
$neutrals-2: #23262F;
$neutrals-3: #353945;
$neutrals-4: #777E90;
$neutrals-5: #B1B5C3;
$neutrals-6: #E6E8EC;
$neutrals-7: #F4F5F6;
$neutrals-8: #FCFCFD;

// Dark Mode Colors
