.how-it-works-page-content__component .info-text {
  margin-bottom: 30px; }

.how-it-works-page-content__component .sections-title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2; }
  @media only screen and (max-width: 500px) {
    .how-it-works-page-content__component .sections-title {
      font-size: 20px; } }

.how-it-works-page-content__component .step-section {
  margin-bottom: 40px; }
  .how-it-works-page-content__component .step-section .section-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .how-it-works-page-content__component .step-section .section-header .icon {
      width: 64px;
      min-width: 64px;
      height: 64px;
      margin-right: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
      .how-it-works-page-content__component .step-section .section-header .icon.step-1 {
        background-image: url("./icons/ic_step_1.svg"); }
      .how-it-works-page-content__component .step-section .section-header .icon.step-2 {
        background-image: url("./icons/ic_step_2.svg"); }
      .how-it-works-page-content__component .step-section .section-header .icon.step-3 {
        background-image: url("./icons/ic_step_3.svg"); }
      @media only screen and (max-width: 500px) {
        .how-it-works-page-content__component .step-section .section-header .icon {
          width: 40px;
          min-width: 40px;
          height: 40px; } }
    .how-it-works-page-content__component .step-section .section-header .text-block .title {
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: 500; }
      @media only screen and (max-width: 500px) {
        .how-it-works-page-content__component .step-section .section-header .text-block .title {
          margin-bottom: 0;
          font-size: 16px; } }
    @media only screen and (max-width: 500px) {
      .how-it-works-page-content__component .step-section .section-header .text-block .undertext {
        font-size: 12px; } }
  .how-it-works-page-content__component .step-section .image-container {
    width: 100%;
    max-width: 938px;
    height: 376px;
    margin: 16px auto 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
    .how-it-works-page-content__component .step-section .image-container.step-1 {
      background-image: url("./images/img_step1.png"); }
      @media only screen and (max-width: 500px) {
        .how-it-works-page-content__component .step-section .image-container.step-1 {
          background-image: url("./images/img_step1_mobile.png"); } }
    .how-it-works-page-content__component .step-section .image-container.step-2 {
      background-image: url("./images/img_step2.png"); }
      @media only screen and (max-width: 500px) {
        .how-it-works-page-content__component .step-section .image-container.step-2 {
          background-image: url("./images/img_step2_mobile.png"); } }
    .how-it-works-page-content__component .step-section .image-container.step-3 {
      background-image: url("./images/img_step3.png"); }
      @media only screen and (max-width: 500px) {
        .how-it-works-page-content__component .step-section .image-container.step-3 {
          background-image: url("./images/img_step3_mobile.png"); } }
    @media only screen and (max-width: 768px) {
      .how-it-works-page-content__component .step-section .image-container {
        height: 315px; } }
    @media only screen and (max-width: 500px) {
      .how-it-works-page-content__component .step-section .image-container {
        max-width: 335px; } }
