.collection-details__component .header-block {
  height: 200px;
  position: relative;
  background-image: url("icons/ic_default_cover.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .collection-details__component .header-block .logo-container {
    width: 160px;
    height: 160px;
    padding: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -80px;
    background: #FFFFFF;
    overflow: hidden; }
    .collection-details__component .header-block .logo-container .common-logo-container__component {
      width: 100%;
      height: 100%; }

.collection-details__component .info-block {
  margin-bottom: 20px;
  padding: 0 20px; }
  .collection-details__component .info-block .title {
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
    word-wrap: break-word;
    line-height: 1.3; }
  .collection-details__component .info-block .token-id {
    width: 200px;
    margin: 0 auto 20px auto;
    padding: 10px 16px;
    text-align: center;
    border: 2px solid #F4F5F6;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    color: #777E90;
    font-size: 16px; }
  .collection-details__component .info-block .created {
    max-width: 400px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: #777E90;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .collection-details__component .info-block .created a {
      font-weight: 500;
      color: #23262F;
      text-decoration: underline; }
  .collection-details__component .info-block .social-icons-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .collection-details__component .info-block .social-icons-wrapper .social-icon {
      margin-right: 5px; }
  .collection-details__component .info-block .stats {
    margin-top: 30px;
    margin-bottom: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .collection-details__component .info-block .stats .stats-item {
      width: 130px;
      margin: 0 5px;
      padding: 20px 0;
      background: #F4F5F6;
      -webkit-border-radius: 24px;
      -moz-border-radius: 24px;
      -ms-border-radius: 24px;
      border-radius: 24px; }
      .collection-details__component .info-block .stats .stats-item .value {
        margin-bottom: 5px;
        text-align: center;
        font-weight: 500;
        font-size: 18px; }
      .collection-details__component .info-block .stats .stats-item .text {
        text-align: center; }
  .collection-details__component .info-block .description {
    max-width: 600px;
    margin: 0 auto 30px auto;
    font-size: 12px;
    color: #777E90;
    text-align: center; }

.collection-details__component .tabs-container {
  margin-bottom: 20px; }

.collection-details__component .content-block {
  width: 100%;
  max-width: 1440px;
  min-height: 300px;
  margin: 0 auto;
  padding: 0 100px; }
  @media only screen and (max-width: 1024px) {
    .collection-details__component .content-block {
      padding: 0 20px; } }
