.text-area-field__component {
  width: 100%;
  margin: 0;
  position: relative; }
  .text-area-field__component .label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #B1B5C3; }
    .text-area-field__component .label span {
      font-weight: 400; }
  .text-area-field__component .errors {
    font-size: 12px;
    color: #FF385C;
    font-weight: 500;
    text-align: right; }
  .text-area-field__component.field textarea {
    padding: 10px 15px;
    line-height: 1.5;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    border-radius: 12px;
    resize: none;
    border: 2px solid #F4F5F6;
    font-family: "Poppins", sans-serif; }
