.common-table-header__component {
  display: table-row;
  color: #777E90;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px; }
  .common-table-header__component .common-table-cell__component {
    padding: 7px 0px 7px 10px;
    border-bottom: none;
    background: #F4F5F6; }
    .common-table-header__component .common-table-cell__component:first-child {
      -webkit-border-radius: 6px 0 0 6px;
      -moz-border-radius: 6px 0 0 6px;
      -ms-border-radius: 6px 0 0 6px;
      border-radius: 6px 0 0 6px; }
    .common-table-header__component .common-table-cell__component:last-child {
      -webkit-border-radius: 0 6px 6px 0;
      -moz-border-radius: 0 6px 6px 0;
      -ms-border-radius: 0 6px 6px 0;
      border-radius: 0 6px 6px 0; }
