.profile-settings-page-content__component {
  padding-bottom: 30px; }
  .profile-settings-page-content__component .text {
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    color: #777E90;
    line-height: 1.6; }
    .profile-settings-page-content__component .text span {
      color: #23262F;
      font-weight: 500; }
