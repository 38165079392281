.top-menu-my-account__component {
  position: relative; }
  .top-menu-my-account__component .user-avatar {
    outline: none;
    cursor: pointer; }
  .top-menu-my-account__component .my-account-popup {
    width: 270px;
    padding: 20px;
    background: #FFFFFF;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    border-radius: 12px;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    border: 1px solid #E6E8EC;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 3; }
    .top-menu-my-account__component .my-account-popup .account-info .username {
      font-size: 18px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .top-menu-my-account__component .my-account-popup .blockchain-wallet {
      margin-top: 16px; }
      .top-menu-my-account__component .my-account-popup .blockchain-wallet .profile-wallet__component {
        width: 100%; }
    .top-menu-my-account__component .my-account-popup .wallet-balance {
      margin-top: 16px;
      padding: 10px 0;
      text-align: center;
      background: #FFEAEE;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      border-radius: 8px; }
      .top-menu-my-account__component .my-account-popup .wallet-balance span {
        font-weight: 500; }
    .top-menu-my-account__component .my-account-popup .nav-links {
      margin-top: 16px; }
      .top-menu-my-account__component .my-account-popup .nav-links .link-item {
        padding: 0 10px;
        line-height: 40px;
        display: block;
        cursor: pointer;
        color: #777E90;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -ms-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center; }
        .top-menu-my-account__component .my-account-popup .nav-links .link-item .icon {
          width: 22px;
          height: 22px;
          margin-right: 10px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
          .top-menu-my-account__component .my-account-popup .nav-links .link-item .icon.profile {
            background-image: url("./icons/ic_profile.svg"); }
          .top-menu-my-account__component .my-account-popup .nav-links .link-item .icon.collections {
            background-image: url("./icons/ic_collections.svg"); }
          .top-menu-my-account__component .my-account-popup .nav-links .link-item .icon.settings {
            background-image: url("./icons/ic_settings.svg"); }
          .top-menu-my-account__component .my-account-popup .nav-links .link-item .icon.logout {
            background-image: url("./icons/ic_disconnect.svg"); }
        .top-menu-my-account__component .my-account-popup .nav-links .link-item:last-child {
          margin-bottom: 0; }
        .top-menu-my-account__component .my-account-popup .nav-links .link-item:hover {
          color: #23262F;
          background: #F4F5F6;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          border-radius: 4px; }
