.fixed-notification__component {
  max-width: 500px;
  min-height: 60px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: auto;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
  background: #00CE67;
  color: #FFFFFF;
  z-index: 9999;
  opacity: 1;
  transition: all 5s;
  font-size: 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .fixed-notification__component.fadeout {
    opacity: 0; }
