.ui.form .field.input-text-field__component {
  position: relative; }
  .ui.form .field.input-text-field__component .fieldset .label-prefix img {
    width: 10px;
    margin-right: 5px; }
  .ui.form .field.input-text-field__component.error .fieldset:not(.is-empty) .label-prefix, .ui.form .field.input-text-field__component.valid .fieldset:not(.is-empty) .label-prefix {
    width: auto;
    visibility: visible; }
  .ui.form .field.input-text-field__component.error .fieldset input + label, .ui.form .field.input-text-field__component.valid .fieldset input + label {
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    font-size: 12px; }
  .ui.form .field.input-text-field__component.error .fieldset:not(.is-empty) .label-prefix {
    width: auto;
    visibility: visible; }
  .ui.form .field.input-text-field__component.error input[data-empty="true"] + label {
    -webkit-transform: translate3d(0, 0px, 0);
    -moz-transform: translate3d(0, 0px, 0);
    -ms-transform: translate3d(0, 0px, 0);
    -o-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0); }
  .ui.form .field.input-text-field__component.error input:focus + label {
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  .ui.form .field.input-text-field__component.error .ui.pointing.label {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    width: 100%;
    line-height: 1.21428571em;
    margin: 0;
    padding: 4px 10px !important;
    color: #FF385C !important;
    background: rgba(255, 56, 92, 0.2) !important;
    text-align: right;
    font-size: 12px;
    font-weight: 500; }
    .ui.form .field.input-text-field__component.error .ui.pointing.label::before {
      display: none; }
  .ui.form .field.input-text-field__component.valid .fieldset {
    border-bottom-color: #00CE67; }
    .ui.form .field.input-text-field__component.valid .fieldset.is-focused {
      border-bottom-color: #00CE67; }
    .ui.form .field.input-text-field__component.valid .fieldset:not(.is-empty) .label-prefix {
      width: auto;
      visibility: visible; }
  .ui.form .field.input-text-field__component .fieldset {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    padding: 0;
    border: none;
    position: relative;
    border-bottom: 1px solid transparent;
    border-bottom-color: #E6E8EC; }
    .ui.form .field.input-text-field__component .fieldset.is-focused {
      border-bottom-color: #3772FF; }
      .ui.form .field.input-text-field__component .fieldset.is-focused .label-prefix {
        -webkit-transition: visibility 0.6s, 0.2s;
        -moz-transition: visibility 0.6s, 0.2s;
        -ms-transition: visibility 0.6s, 0.2s;
        -o-transition: visibility 0.6s, 0.2s;
        transition: visibility 0.6s, 0.2s;
        width: auto;
        visibility: visible; }
  .ui.form .field.input-text-field__component input {
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    display: block;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    background: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .ui.form .field.input-text-field__component input:-webkit-autofill {
      border-color: unset;
      -webkit-box-shadow: 0 0 0 100px #FFFFFF inset !important;
      -moz-box-shadow: 0 0 0 100px #FFFFFF inset !important;
      box-shadow: 0 0 0 100px #FFFFFF inset !important; }
      .ui.form .field.input-text-field__component input:-webkit-autofill:focus {
        border-color: unset;
        -webkit-box-shadow: 0 0 0 100px #FFFFFF inset !important;
        -moz-box-shadow: 0 0 0 100px #FFFFFF inset !important;
        box-shadow: 0 0 0 100px #FFFFFF inset !important; }
    .ui.form .field.input-text-field__component input:focus + label {
      -webkit-transform: translate3d(0, -20px, 0);
      -moz-transform: translate3d(0, -20px, 0);
      -ms-transform: translate3d(0, -20px, 0);
      -o-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
      font-size: 12px; }
  .ui.form .field.input-text-field__component label {
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    position: absolute;
    top: 0.678571em;
    line-height: 1.21428571em;
    left: 0;
    margin: 0 !important;
    touch-action: none;
    pointer-events: none;
    color: #777E90; }
  .ui.form .field.input-text-field__component .label-prefix {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-transition: visibility 0.6s, 0s;
    -moz-transition: visibility 0.6s, 0s;
    -ms-transition: visibility 0.6s, 0s;
    -o-transition: visibility 0.6s, 0s;
    transition: visibility 0.6s, 0s;
    display: block;
    width: 0;
    visibility: hidden;
    touch-action: none;
    pointer-events: none;
    color: #777E90; }
