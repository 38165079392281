.profile-wallet__component {
  width: 200px;
  padding: 10px 20px;
  border: 2px solid #F4F5F6;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  text-align: center;
  color: #777E90;
  font-size: 16px;
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .profile-wallet__component .copy-icon {
    width: 20px;
    height: 20px;
    background-image: url("./icons/ic_copy.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
