.upload-profile-avatar__component {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .upload-profile-avatar__component .common-logo-container__component {
    margin-right: 20px; }
  .upload-profile-avatar__component .info-block .text-block {
    margin-bottom: 10px; }
    .upload-profile-avatar__component .info-block .text-block .title {
      margin-bottom: 5px;
      font-family: "Poppins", sans-serif;
      color: #23262F;
      font-size: 18px;
      font-weight: 500; }
    .upload-profile-avatar__component .info-block .text-block .undertext {
      font-family: "Poppins", sans-serif;
      color: #777E90;
      line-height: 1.6;
      font-size: 12px; }
  .upload-profile-avatar__component .info-block .buttons-block {
    width: 110px; }
