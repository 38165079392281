.add-edit-collection-buttons__component {
  padding: 20px 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .add-edit-collection-buttons__component a {
    margin: 0 5px; }
    .add-edit-collection-buttons__component a .ui.button.button__component {
      width: 200px; }
    @media only screen and (max-width: 450px) {
      .add-edit-collection-buttons__component a {
        margin-bottom: 10px; } }
  @media only screen and (max-width: 450px) {
    .add-edit-collection-buttons__component {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; } }
