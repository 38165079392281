.common-table__component {
  width: 100%; }
  .common-table__component .table-wrapper {
    width: 100%;
    display: table; }
    .common-table__component .table-wrapper > .common-table-row__component:last-child .common-table-cell__component {
      border-bottom: none; }
  @media only screen and (max-width: 1280px) {
    .common-table__component.mobile-1280 {
      display: block; }
      .common-table__component.mobile-1280 .common-table-header__component {
        display: none; }
      .common-table__component.mobile-1280 .common-table-row__component {
        display: block;
        border-bottom: 1px solid #E6E8EC; }
        .common-table__component.mobile-1280 .common-table-row__component:last-child {
          border-bottom: none; }
          .common-table__component.mobile-1280 .common-table-row__component:last-child .common-table-cell__component {
            border-bottom: 1px solid #E6E8EC; }
            .common-table__component.mobile-1280 .common-table-row__component:last-child .common-table-cell__component:last-child {
              border-bottom: none; }
        .common-table__component.mobile-1280 .common-table-row__component .common-table-cell__component {
          padding: 5px 0;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          -o-flex-wrap: wrap;
          flex-wrap: wrap; }
          .common-table__component.mobile-1280 .common-table-row__component .common-table-cell__component:last-child {
            padding-right: 0; }
          .common-table__component.mobile-1280 .common-table-row__component .common-table-cell__component .parameter {
            display: block;
            font-weight: 500; }
          .common-table__component.mobile-1280 .common-table-row__component .common-table-cell__component .value {
            color: #777E90; } }
  @media only screen and (max-width: 1024px) {
    .common-table__component.mobile-1024 {
      display: block; }
      .common-table__component.mobile-1024 .common-table-header__component {
        display: none; }
      .common-table__component.mobile-1024 .common-table-row__component {
        display: block;
        border-bottom: 1px solid #E6E8EC; }
        .common-table__component.mobile-1024 .common-table-row__component:last-child {
          border-bottom: none; }
          .common-table__component.mobile-1024 .common-table-row__component:last-child .common-table-cell__component {
            border-bottom: 1px solid #E6E8EC; }
            .common-table__component.mobile-1024 .common-table-row__component:last-child .common-table-cell__component:last-child {
              border-bottom: none; }
        .common-table__component.mobile-1024 .common-table-row__component .common-table-cell__component {
          padding: 5px 0;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          -o-flex-wrap: wrap;
          flex-wrap: wrap; }
          .common-table__component.mobile-1024 .common-table-row__component .common-table-cell__component:last-child {
            padding-right: 0; }
          .common-table__component.mobile-1024 .common-table-row__component .common-table-cell__component .parameter {
            display: block;
            font-weight: 500; }
          .common-table__component.mobile-1024 .common-table-row__component .common-table-cell__component .value {
            color: #777E90; } }
  @media only screen and (max-width: 768px) {
    .common-table__component.mobile-768 {
      display: block; }
      .common-table__component.mobile-768 .common-table-header__component {
        display: none; }
      .common-table__component.mobile-768 .common-table-row__component {
        display: block;
        border-bottom: 1px solid #E6E8EC; }
        .common-table__component.mobile-768 .common-table-row__component:last-child {
          border-bottom: none; }
          .common-table__component.mobile-768 .common-table-row__component:last-child .common-table-cell__component {
            border-bottom: 1px solid #E6E8EC; }
            .common-table__component.mobile-768 .common-table-row__component:last-child .common-table-cell__component:last-child {
              border-bottom: none; }
        .common-table__component.mobile-768 .common-table-row__component .common-table-cell__component {
          padding: 5px 0;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          -o-flex-wrap: wrap;
          flex-wrap: wrap; }
          .common-table__component.mobile-768 .common-table-row__component .common-table-cell__component:last-child {
            padding-right: 0; }
          .common-table__component.mobile-768 .common-table-row__component .common-table-cell__component .parameter {
            display: block;
            font-weight: 500; }
          .common-table__component.mobile-768 .common-table-row__component .common-table-cell__component .value {
            color: #777E90; } }
  @media only screen and (max-width: 500px) {
    .common-table__component.mobile-500 {
      display: block; }
      .common-table__component.mobile-500 .common-table-header__component {
        display: none; }
      .common-table__component.mobile-500 .common-table-row__component {
        display: block;
        border-bottom: 1px solid #E6E8EC; }
        .common-table__component.mobile-500 .common-table-row__component:last-child {
          border-bottom: none; }
          .common-table__component.mobile-500 .common-table-row__component:last-child .common-table-cell__component {
            border-bottom: 1px solid #E6E8EC; }
            .common-table__component.mobile-500 .common-table-row__component:last-child .common-table-cell__component:last-child {
              border-bottom: none; }
        .common-table__component.mobile-500 .common-table-row__component .common-table-cell__component {
          padding: 5px 0;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          -o-flex-wrap: wrap;
          flex-wrap: wrap; }
          .common-table__component.mobile-500 .common-table-row__component .common-table-cell__component:last-child {
            padding-right: 0; }
          .common-table__component.mobile-500 .common-table-row__component .common-table-cell__component .parameter {
            display: block;
            font-weight: 500; }
          .common-table__component.mobile-500 .common-table-row__component .common-table-cell__component .value {
            color: #777E90; } }
