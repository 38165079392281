.select-field__component .label {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 12px;
  color: #B1B5C3; }
  .select-field__component .label span {
    font-weight: 400; }

.select-field__component .errors {
  font-size: 12px;
  color: #FF385C;
  font-weight: 500;
  text-align: right; }

.select-field__component .ui.selection.dropdown {
  width: 100%;
  height: 45px;
  border: 2px solid #F4F5F6;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px; }
  .select-field__component .ui.selection.dropdown > .text {
    width: 100%;
    padding-right: 20px;
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    font-family: "Poppins", sans-serif; }
  .select-field__component .ui.selection.dropdown > .icon {
    top: 12.5px !important; }
  .select-field__component .ui.selection.dropdown .menu {
    width: calc(100% + 2px);
    min-width: calc(100% + 2px);
    margin-top: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #FFFFFF;
    border: 1px solid #E6E8EC; }
    .select-field__component .ui.selection.dropdown .menu .item {
      border-top: none; }
      .select-field__component .ui.selection.dropdown .menu .item .text {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: "Poppins", sans-serif; }
