.ui.modal.confirmation-modal__component .text-block {
  margin-bottom: 20px;
  text-align: center; }

.ui.modal.confirmation-modal__component .content-block {
  margin-bottom: 20px; }

.ui.modal.confirmation-modal__component .buttons-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .ui.modal.confirmation-modal__component .buttons-block .ui.button.button__component {
    margin: 0 5px; }
