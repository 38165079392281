.auth-layout__component {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .auth-layout__component > div {
    width: 50%; }
  .auth-layout__component .left-block {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("./images/img_auth_background.jpeg");
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .auth-layout__component .left-block .header-menu {
      height: 100px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .auth-layout__component .left-block .header-menu .main-logo__component {
        margin-right: 20px; }
      .auth-layout__component .left-block .header-menu .divider {
        margin-right: 20px;
        color: #FFFFFF; }
      .auth-layout__component .left-block .header-menu .text {
        color: #FFFFFF; }
    .auth-layout__component .left-block .image-block {
      margin-top: 50px;
      -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto; }
      @media only screen and (max-width: 1024px) {
        .auth-layout__component .left-block .image-block {
          margin-top: 150px; } }
      .auth-layout__component .left-block .image-block .common-nft-slider__component .react-3d-carousel {
        width: 90% !important;
        height: 476px !important; }
        .auth-layout__component .left-block .image-block .common-nft-slider__component .react-3d-carousel .image {
          width: 560px;
          border-radius: 15px; }
    @media only screen and (max-width: 768px) {
      .auth-layout__component .left-block {
        display: none; } }
  .auth-layout__component .right-block {
    padding: 200px 0; }
    .auth-layout__component .right-block .form-container {
      max-width: 500px;
      margin: 0 auto;
      padding: 20px; }
    @media only screen and (max-width: 768px) {
      .auth-layout__component .right-block {
        width: 100%;
        padding: 100px 0; } }
