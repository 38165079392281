.registration-success__component h1 {
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 1;
  font-size: 36px;
  text-align: center; }

.registration-success__component .email {
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 500; }

.registration-success__component .text {
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  color: #777E90;
  line-height: 1.6; }

.registration-success__component .ui.button.button__component {
  width: 100%; }
  .registration-success__component .ui.button.button__component .button-text {
    margin-right: 10px; }
  .registration-success__component .ui.button.button__component .icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./icons/ic_arrow.svg"); }
