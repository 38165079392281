.common-message__component {
  padding: 10px 16px;
  overflow: hidden;
  line-height: 1.7;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .common-message__component .icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
  .common-message__component .content-wrapper {
    width: 100%;
    padding: 10px 16px; }
  .common-message__component .bold {
    font-weight: bold; }
  .common-message__component.info {
    background: #f4f5f9; }
    .common-message__component.info .icon {
      background-image: url("./icons/ic_info.svg"); }
  .common-message__component.success {
    background: #D3FFE9; }
    .common-message__component.success .icon {
      background-image: url("./icons/ic_success.svg"); }
  .common-message__component.warning {
    background: #fff8dd; }
    .common-message__component.warning .icon {
      background-image: url("./icons/ic_warning.svg"); }
  .common-message__component.error {
    background: #ffecec; }
    .common-message__component.error .icon {
      background-image: url("./icons/ic_error.svg"); }
    .common-message__component.error .content-wrapper {
      color: #FF385C; }
