.common-footer__component {
  padding: 30px 0; }
  .common-footer__component .navigation {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #E6E8EC;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    .common-footer__component .navigation .section {
      -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto; }
      .common-footer__component .navigation .section.logo .main-logo__component {
        margin-bottom: 20px; }
      .common-footer__component .navigation .section.logo .underlogo {
        font-size: 18px; }
      @media only screen and (max-width: 768px) {
        .common-footer__component .navigation .section.logo {
          display: none; } }
      .common-footer__component .navigation .section .section-title {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px; }
        @media only screen and (max-width: 500px) {
          .common-footer__component .navigation .section .section-title {
            margin-bottom: 10px; } }
      .common-footer__component .navigation .section .nav-item {
        margin-bottom: 10px;
        display: block;
        color: #777E90; }
        .common-footer__component .navigation .section .nav-item:last-child {
          margin-bottom: 0; }
      @media only screen and (max-width: 500px) {
        .common-footer__component .navigation .section {
          width: 100%;
          margin-bottom: 30px;
          text-align: center; }
          .common-footer__component .navigation .section:last-child {
            margin-bottom: 0; } }
    @media only screen and (max-width: 500px) {
      .common-footer__component .navigation {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap; } }
  .common-footer__component .under {
    color: #777E90;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .common-footer__component .under .powered {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      .common-footer__component .under .powered .text {
        margin-right: 10px;
        margin-bottom: 0; }
      .common-footer__component .under .powered .logo {
        display: block;
        width: 36px;
        min-width: 36px;
        height: 36px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("./icons/ic_logo.svg"); }
      .common-footer__component .under .powered a {
        color: #777E90;
        text-decoration: underline;
        font-weight: 500; }
    .common-footer__component .under .terms-privacy-wrapper .terms, .common-footer__component .under .terms-privacy-wrapper .privacy {
      margin-right: 20px;
      color: #777E90;
      font-weight: 500;
      text-decoration: none; }
    @media screen and (max-width: 768px) {
      .common-footer__component .under {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column; }
        .common-footer__component .under div {
          margin-bottom: 5px; } }
