.main-mobile-menu__component .mobile-menu-icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./icons/ic_mobile_menu.svg");
  cursor: pointer;
  outline: none; }

.main-mobile-menu__component .mobile-menu-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #FFFFFF;
  overflow-y: auto;
  top: 0;
  left: 0;
  z-index: 10;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .main-mobile-menu__component .mobile-menu-wrapper .header {
    height: 82px;
    padding: 0 16px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .main-mobile-menu__component .mobile-menu-wrapper .header .logo-container {
      outline: none;
      cursor: pointer; }
      @media only screen and (max-width: 400px) {
        .main-mobile-menu__component .mobile-menu-wrapper .header .logo-container .main-logo__component .heylink-logo {
          margin-right: 10px; }
        .main-mobile-menu__component .mobile-menu-wrapper .header .logo-container .main-logo__component .text-logo {
          display: none; } }
    .main-mobile-menu__component .mobile-menu-wrapper .header .close-button {
      width: 40px;
      min-width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("./icons/ic_mobile_close.svg");
      cursor: pointer;
      outline: none; }
  .main-mobile-menu__component .mobile-menu-wrapper .mobile-search {
    padding: 16px 32px; }
  .main-mobile-menu__component .mobile-menu-wrapper .account-info {
    padding: 0 32px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .main-mobile-menu__component .mobile-menu-wrapper .account-info .common-logo-container__component {
      margin-right: 20px; }
    .main-mobile-menu__component .mobile-menu-wrapper .account-info .info {
      max-width: calc(100% - 70px);
      color: #23262F; }
      .main-mobile-menu__component .mobile-menu-wrapper .account-info .info .username {
        font-weight: 500;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
  .main-mobile-menu__component .mobile-menu-wrapper .blockchain-info {
    margin-top: 16px;
    padding: 0 32px; }
    .main-mobile-menu__component .mobile-menu-wrapper .blockchain-info .profile-wallet__component {
      width: 100%; }
    .main-mobile-menu__component .mobile-menu-wrapper .blockchain-info .wallet-balance {
      margin-top: 16px;
      padding: 12px 0;
      text-align: center;
      background: #FFEAEE;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      border-radius: 30px; }
      .main-mobile-menu__component .mobile-menu-wrapper .blockchain-info .wallet-balance span {
        font-weight: 500; }
  .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper {
    margin-top: 16px;
    padding: 0 32px; }
    .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item {
      height: 50px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item:hover .text {
        color: #FF385C; }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item:hover .icon.create {
        background-image: url("./icons/ic_create_active.svg"); }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item:hover .icon.explore {
        background-image: url("./icons/ic_explore_active.svg"); }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item:hover .icon.how-it-works {
        background-image: url("./icons/ic_how_active.svg"); }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item:hover .icon.contact {
        background-image: url("./icons/ic_contact_active.svg"); }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item:hover .icon.profile {
        background-image: url("./icons/ic_profile_active.svg"); }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item:hover .icon.collections {
        background-image: url("./icons/ic_collections_active.svg"); }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item:hover .icon.settings {
        background-image: url("./icons/ic_settings_active.svg"); }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item:hover .icon.pricing {
        background-image: url("./icons/ic_pricing_active.svg"); }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .text {
        color: #777E90;
        font-size: 20px;
        -webkit-transition: background 0.1s ease-in-out;
        -moz-transition: background 0.1s ease-in-out;
        -ms-transition: background 0.1s ease-in-out;
        -o-transition: background 0.1s ease-in-out;
        transition: background 0.1s ease-in-out; }
      .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .icon {
        width: 25px;
        min-width: 25px;
        height: 25px;
        margin-right: 10px;
        -webkit-transition: background 0.1s ease-in-out;
        -moz-transition: background 0.1s ease-in-out;
        -ms-transition: background 0.1s ease-in-out;
        -o-transition: background 0.1s ease-in-out;
        transition: background 0.1s ease-in-out;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain; }
        .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .icon.create {
          background-image: url("./icons/ic_create.svg"); }
        .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .icon.explore {
          background-image: url("./icons/ic_explore.svg"); }
        .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .icon.how-it-works {
          background-image: url("./icons/ic_how.svg"); }
        .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .icon.contact {
          background-image: url("./icons/ic_contact.svg"); }
        .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .icon.profile {
          background-image: url("./icons/ic_profile.svg"); }
        .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .icon.collections {
          background-image: url("./icons/ic_collections.svg"); }
        .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .icon.settings {
          background-image: url("./icons/ic_settings.svg"); }
        .main-mobile-menu__component .mobile-menu-wrapper .nav-wrapper .nav-item .icon.pricing {
          background-image: url("./icons/ic_pricing.svg"); }
  .main-mobile-menu__component .mobile-menu-wrapper .auth-buttons {
    margin-top: 20px;
    padding: 0 32px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    .main-mobile-menu__component .mobile-menu-wrapper .auth-buttons a {
      width: 100%;
      display: block; }
      .main-mobile-menu__component .mobile-menu-wrapper .auth-buttons a:first-child {
        margin-right: 5px; }
        @media only screen and (max-width: 500px) {
          .main-mobile-menu__component .mobile-menu-wrapper .auth-buttons a:first-child {
            margin-right: 0;
            margin-bottom: 16px; } }
      .main-mobile-menu__component .mobile-menu-wrapper .auth-buttons a:last-child {
        margin-left: 5px; }
        @media only screen and (max-width: 500px) {
          .main-mobile-menu__component .mobile-menu-wrapper .auth-buttons a:last-child {
            margin-left: 0; } }
      .main-mobile-menu__component .mobile-menu-wrapper .auth-buttons a .ui.button.button__component {
        width: 100%; }
    @media only screen and (max-width: 500px) {
      .main-mobile-menu__component .mobile-menu-wrapper .auth-buttons {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap; } }
  .main-mobile-menu__component .mobile-menu-wrapper .disconnect-button {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0 32px; }
    .main-mobile-menu__component .mobile-menu-wrapper .disconnect-button .ui.button.button__component {
      width: 100%; }
