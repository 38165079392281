.assets-filters-bar__component {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap; }
  .assets-filters-bar__component > div:first-child {
    margin-right: 10px; }
    @media only screen and (max-width: 500px) {
      .assets-filters-bar__component > div:first-child {
        margin-right: 0;
        margin-bottom: 10px; } }
  @media only screen and (max-width: 500px) {
    .assets-filters-bar__component > div {
      width: 100%; } }
  @media only screen and (max-width: 500px) {
    .assets-filters-bar__component {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; } }
