.not-found__component {
  width: 100%;
  height: 100vh;
  font-family: "DM Sans", sans-serif;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .not-found__component > div {
    width: 45%; }
  .not-found__component .image-container {
    margin-right: 100px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
    .not-found__component .image-container .image {
      width: 400px;
      min-width: 450px;
      height: 275px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("icons/ic_404.svg"); }
    @media only screen and (max-width: 768px) {
      .not-found__component .image-container {
        display: none; } }
  .not-found__component .text-container .title {
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 100px; }
  .not-found__component .text-container .info-text {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.2; }
  .not-found__component .text-container .undertext {
    margin-bottom: 30px;
    font-size: 16px; }
  .not-found__component .text-container .links-block a {
    color: #FF385C;
    font-size: 16px;
    font-weight: 500; }
    .not-found__component .text-container .links-block a:first-child {
      margin-right: 30px; }
  @media only screen and (max-width: 768px) {
    .not-found__component .text-container {
      width: 100%;
      padding: 0 16px; }
      .not-found__component .text-container > div {
        text-align: center; } }
