.hedera-account-info__component .common-message__component {
  margin-bottom: 16px; }

.hedera-account-info__component .title-block {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .hedera-account-info__component .title-block .icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 16px;
    background-image: url("icons/ic_hedera_icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .hedera-account-info__component .title-block .title {
    font-weight: 500;
    font-size: 16px; }
    .hedera-account-info__component .title-block .title .wallet {
      font-size: 14px;
      color: #777E90;
      font-weight: 400; }

.hedera-account-info__component .wallet-balance {
  margin-bottom: 10px;
  padding: 10px 16px;
  background: #F4F5F6;
  text-align: center;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px; }
  .hedera-account-info__component .wallet-balance span {
    font-weight: 500;
    font-size: 16px; }

.hedera-account-info__component .status {
  height: 40px;
  padding: 0 16px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .hedera-account-info__component .status.not-connected {
    background: #F4F5F6; }
    .hedera-account-info__component .status.not-connected .icon {
      background-image: url("icons/ic_not_connected.svg"); }
    .hedera-account-info__component .status.not-connected .status-text {
      color: #B1B5C3; }
  .hedera-account-info__component .status.connected {
    background: #D3FFE9; }
    .hedera-account-info__component .status.connected .icon {
      background-image: url("icons/ic_connected.svg"); }
    .hedera-account-info__component .status.connected .status-text {
      color: #00CE67; }
  .hedera-account-info__component .status .icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .hedera-account-info__component .status .status-text {
    font-weight: 500; }

.hedera-account-info__component .ui.button.button__component {
  width: 100%;
  margin-top: 16px; }
