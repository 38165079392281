.about-and-founders__component .title {
  margin: 50px 0 30px 0;
  font-weight: 600;
  font-size: 24px;
  color: #23262F;
  text-align: center; }

.about-and-founders__component .line {
  margin: 0 auto 20px;
  width: 100px;
  height: 1px;
  background: #FF385C; }

.about-and-founders__component .text-wrapper {
  margin-top: 0px auto;
  text-align: center; }
  .about-and-founders__component .text-wrapper div {
    max-width: 640px;
    margin: 10px auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%; }

.about-and-founders__component .founders-wrapper {
  margin-top: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around; }
  @media screen and (max-width: 768px) {
    .about-and-founders__component .founders-wrapper {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column; } }
  @media screen and (max-width: 768px) {
    .about-and-founders__component .founders-wrapper .founder {
      margin-bottom: 30px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; } }
  .about-and-founders__component .founders-wrapper .founder img {
    width: 200px; }
  .about-and-founders__component .founders-wrapper .founder .founder-name {
    margin-top: 30px;
    font-weight: 700;
    text-align: center; }
  .about-and-founders__component .founders-wrapper .founder .job-title,
  .about-and-founders__component .founders-wrapper .founder .e-mail,
  .about-and-founders__component .founders-wrapper .founder .link {
    font-size: 16px;
    line-height: 150%;
    text-align: center; }
  .about-and-founders__component .founders-wrapper .founder .link {
    margin-top: 20px; }
    .about-and-founders__component .founders-wrapper .founder .link a {
      text-decoration: underline;
      color: #FF385C; }

.about-and-founders__component .social-wrapper {
  margin-top: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .about-and-founders__component .social-wrapper a {
    margin: 0 10px; }
    @media screen and (max-width: 410px) {
      .about-and-founders__component .social-wrapper a {
        margin: 0 5px; }
        .about-and-founders__component .social-wrapper a img {
          width: 46px; } }

.about-and-founders__component .awards-wrapper .awards-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  @media screen and (max-width: 800px) {
    .about-and-founders__component .awards-wrapper .awards-content {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; } }
  @media screen and (max-width: 800px) {
    .about-and-founders__component .awards-wrapper .awards-content a {
      width: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; } }
  .about-and-founders__component .awards-wrapper .awards-content a div {
    height: 140px;
    width: 250px;
    margin: 0 10px;
    border: 1px solid #F4F5F6;
    box-shadow: 0px 2px 20px rgba(20, 20, 43, 0.12);
    border-radius: 8px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    @media screen and (max-width: 800px) {
      .about-and-founders__component .awards-wrapper .awards-content a div {
        width: 80%;
        margin-bottom: 30px; } }
  .about-and-founders__component .awards-wrapper .awards-content a .visa {
    padding-top: 35px; }

.about-and-founders__component .awards-wrapper .accelerated-content {
  margin-top: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  @media screen and (max-width: 800px) {
    .about-and-founders__component .awards-wrapper .accelerated-content {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column; } }
  .about-and-founders__component .awards-wrapper .accelerated-content a, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate {
    position: relative; }
    @media screen and (max-width: 800px) {
      .about-and-founders__component .awards-wrapper .accelerated-content a, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate {
        width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center; } }
    .about-and-founders__component .awards-wrapper .accelerated-content a .text, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .text {
      font-weight: 500;
      font-size: 12px;
      color: #23262F;
      position: absolute;
      top: 10px; }
    .about-and-founders__component .awards-wrapper .accelerated-content a div, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate div {
      height: 140px;
      margin: 0 10px;
      margin: 0 10px;
      border: 1px solid #F4F5F6;
      box-shadow: 0px 2px 20px rgba(20, 20, 43, 0.12);
      border-radius: 8px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      @media screen and (max-width: 800px) {
        .about-and-founders__component .awards-wrapper .accelerated-content a div, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate div {
          width: 80%;
          margin-bottom: 30px; } }
    .about-and-founders__component .awards-wrapper .accelerated-content a .side, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .side {
      width: 220px; }
      @media screen and (max-width: 800px) {
        .about-and-founders__component .awards-wrapper .accelerated-content a .side, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .side {
          width: 80%; } }
      .about-and-founders__component .awards-wrapper .accelerated-content a .side a:first-child, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .side a:first-child {
        margin-top: 15px; }
        .about-and-founders__component .awards-wrapper .accelerated-content a .side a:first-child img, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .side a:first-child img {
          margin-bottom: 5px; }
      .about-and-founders__component .awards-wrapper .accelerated-content a .side img, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .side img {
        margin-bottom: 5px; }
    .about-and-founders__component .awards-wrapper .accelerated-content a .two-in-one, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .two-in-one {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column; }
      .about-and-founders__component .awards-wrapper .accelerated-content a .two-in-one img, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .two-in-one img {
        margin-top: 5px; }
    .about-and-founders__component .awards-wrapper .accelerated-content a .middle, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .middle {
      width: 310px; }
      @media screen and (max-width: 800px) {
        .about-and-founders__component .awards-wrapper .accelerated-content a .middle, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .middle {
          width: 80%; }
          .about-and-founders__component .awards-wrapper .accelerated-content a .middle img, .about-and-founders__component .awards-wrapper .accelerated-content .muru-incubate .middle img {
            width: 80%; } }

.about-and-founders__component .partners-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  @media screen and (max-width: 800px) {
    .about-and-founders__component .partners-wrapper a {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%; } }
  .about-and-founders__component .partners-wrapper a .partners {
    height: 140px;
    width: 320px;
    margin: 0 10px;
    border: 1px solid #F4F5F6;
    box-shadow: 0px 2px 20px rgba(20, 20, 43, 0.12);
    border-radius: 8px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    @media screen and (max-width: 800px) {
      .about-and-founders__component .partners-wrapper a .partners {
        width: 80%; } }

.about-and-founders__component .featured-trusted-wrapper .first-line-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  @media screen and (max-width: 768px) {
    .about-and-founders__component .featured-trusted-wrapper .first-line-wrapper {
      flex-wrap: wrap; } }
  @media screen and (max-width: 395px) {
    .about-and-founders__component .featured-trusted-wrapper .first-line-wrapper a {
      width: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; } }
  .about-and-founders__component .featured-trusted-wrapper .first-line-wrapper a .featured-trusted-item {
    width: 150px;
    height: 100px;
    margin: 0 10px;
    border: 1px solid #F4F5F6;
    box-shadow: 0px 2px 20px rgba(20, 20, 43, 0.12);
    border-radius: 8px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .about-and-founders__component .featured-trusted-wrapper .first-line-wrapper a .featured-trusted-item {
        margin-bottom: 20px; } }
    @media screen and (max-width: 395px) {
      .about-and-founders__component .featured-trusted-wrapper .first-line-wrapper a .featured-trusted-item {
        width: 80%; } }

.about-and-founders__component .featured-trusted-wrapper .second-line-wrapper {
  margin-top: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  @media screen and (max-width: 768px) {
    .about-and-founders__component .featured-trusted-wrapper .second-line-wrapper {
      flex-wrap: wrap; } }
  @media screen and (max-width: 395px) {
    .about-and-founders__component .featured-trusted-wrapper .second-line-wrapper a {
      width: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; } }
  .about-and-founders__component .featured-trusted-wrapper .second-line-wrapper a .featured-trusted-item {
    width: 150px;
    height: 100px;
    margin: 0 10px;
    border: 1px solid #F4F5F6;
    box-shadow: 0px 2px 20px rgba(20, 20, 43, 0.12);
    border-radius: 8px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .about-and-founders__component .featured-trusted-wrapper .second-line-wrapper a .featured-trusted-item {
        margin-bottom: 20px; } }
    @media screen and (max-width: 395px) {
      .about-and-founders__component .featured-trusted-wrapper .second-line-wrapper a .featured-trusted-item {
        width: 80%; } }
