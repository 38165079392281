.asset-list-item__component {
  width: 100%;
  padding: 8px;
  display: block; }
  .asset-list-item__component:hover .image-wrapper {
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    border-radius: 24px;
    overflow: hidden; }
    .asset-list-item__component:hover .image-wrapper .image {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2); }
  .asset-list-item__component .image-wrapper {
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    border-radius: 24px;
    background-color: #E6E8EC;
    overflow: hidden; }
    .asset-list-item__component .image-wrapper .image {
      width: 100%;
      height: 250px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
  .asset-list-item__component .info-wrapper {
    padding: 20px 16px;
    color: #23262F; }
    .asset-list-item__component .info-wrapper .collection {
      margin-bottom: 5px;
      font-family: "DM Sans", sans-serif;
      display: block;
      color: #9757D7;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #777E90; }
      .asset-list-item__component .info-wrapper .collection:hover {
        text-decoration: underline; }
    .asset-list-item__component .info-wrapper .asset-name {
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .asset-list-item__component .info-wrapper .divider {
      margin: 16px 0;
      border-bottom: 1px solid #E6E8EC; }
    .asset-list-item__component .info-wrapper .sale-info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .asset-list-item__component .info-wrapper .sale-info .label {
        font-size: 12px;
        color: #B1B5C3; }
      .asset-list-item__component .info-wrapper .sale-info .price {
        min-width: 70px;
        color: #00CE67;
        font-size: 12px;
        font-weight: 500;
        font-size: 14px;
        text-align: right; }
        .asset-list-item__component .info-wrapper .sale-info .price.not-listed {
          color: #B1B5C3;
          font-weight: 400;
          font-size: 12px; }
