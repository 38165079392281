.breadcrambs__component {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .breadcrambs__component .link-item {
    color: #777E90; }
    .breadcrambs__component .link-item:last-child {
      color: #23262F;
      font-weight: 500; }
  .breadcrambs__component .arrow-block {
    width: 40px;
    min-width: 40px; }
    .breadcrambs__component .arrow-block .arrow {
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin: 0 auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("./icons/ic_right_arrow.svg"); }
    .breadcrambs__component .arrow-block:first-child {
      display: none; }
