.main-logo__component {
  width: 121px;
  min-width: 121px;
  height: 34px;
  margin-right: 10px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("icons/ic_main_logo_beta.svg"); }
  .main-logo__component.white {
    background-image: url("icons/ic_main_logo_beta_white.svg"); }
