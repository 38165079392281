.collection-list-item__component {
  padding: 8px;
  display: block; }
  .collection-list-item__component .image-container {
    height: 150px;
    position: relative; }
    .collection-list-item__component .image-container .banner {
      height: 150px;
      -webkit-border-radius: 24px;
      -moz-border-radius: 24px;
      -ms-border-radius: 24px;
      border-radius: 24px;
      background-image: url("icons/ic_default_banner.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center; }
    .collection-list-item__component .image-container .common-logo-container__component {
      width: 60px;
      height: 60px;
      position: absolute;
      margin: 0 auto;
      bottom: -30px;
      left: 0;
      right: 0; }
  .collection-list-item__component .info-container {
    padding: 40px 16px 16px 16px; }
    .collection-list-item__component .info-container .title {
      margin-bottom: 10px;
      text-align: center;
      color: #23262F;
      font-weight: 500;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .collection-list-item__component .info-container .divider {
      margin: 16px 0;
      border-bottom: 1px solid #E6E8EC; }
    .collection-list-item__component .info-container .statistics {
      font-size: 12px;
      color: #B1B5C3; }
