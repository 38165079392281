.asset-details-info__component {
  padding-top: 10px; }
  .asset-details-info__component .token-name {
    margin-bottom: 20px;
    font-family: "DM Sans", sans-serif;
    font-size: 40px;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 1.2; }
  .asset-details-info__component .info-section {
    margin-bottom: 16px;
    color: #777E90;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .asset-details-info__component .info-section:last-child {
      margin-bottom: 0; }
    .asset-details-info__component .info-section .common-logo-container__component {
      margin-right: 10px; }
    .asset-details-info__component .info-section .info-block .link {
      width: 290px;
      display: block;
      color: #23262F;
      font-weight: 500;
      text-decoration: underline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
