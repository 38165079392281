.upload-collection-banner__component .title {
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  color: #23262F;
  font-size: 18px;
  font-weight: 500; }

.upload-collection-banner__component .undertext {
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  color: #777E90;
  line-height: 1.6;
  font-size: 12px; }

.upload-collection-banner__component .dropzone-container {
  width: 100%;
  height: 200px;
  padding: 5px;
  border: 2px dashed #E6E8EC;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
  overflow: hidden; }
  .upload-collection-banner__component .dropzone-container .dropzone-area {
    width: 100%;
    height: 100%;
    background: #F4F5F6;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    border-radius: 16px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .upload-collection-banner__component .dropzone-container .dropzone-area .info-block .icon {
      width: 48px;
      height: 48px;
      margin: 0 auto 10px auto;
      background-image: url("icons/ic_banner.svg");
      background-size: contain; }
    .upload-collection-banner__component .dropzone-container .dropzone-area .info-block .text {
      text-align: center;
      font-size: 12px;
      color: #777E90; }
