.profile-info__component {
  padding-top: 80px; }
  .profile-info__component .username {
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    font-size: 24px;
    word-wrap: break-word;
    line-height: 1.3; }
  .profile-info__component .profile-wallet__component {
    margin: 20px auto 0 auto; }
  .profile-info__component .description {
    width: 100%;
    max-width: 800px;
    margin: 20px auto 0 auto;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #777E90;
    line-height: 1.6; }
