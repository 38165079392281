.common-page-layout__component {
  width: 100%;
  height: 100vh;
  position: relative; }
  .common-page-layout__component .main-wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 4;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .common-page-layout__component .main-wrapper .top-menu {
      width: 100%;
      border: 1px solid #E6E8EC;
      position: fixed;
      z-index: 100;
      background: #FFFFFF; }
      .common-page-layout__component .main-wrapper .top-menu .top-menu__component {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 100px; }
        @media only screen and (max-width: 1024px) {
          .common-page-layout__component .main-wrapper .top-menu .top-menu__component {
            padding: 0 20px; } }
    .common-page-layout__component .main-wrapper .content-area {
      margin-top: 80px;
      border-bottom: 1px solid #E6E8EC;
      -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto; }
    .common-page-layout__component .main-wrapper .common-footer {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 100px; }
      @media only screen and (max-width: 1024px) {
        .common-page-layout__component .main-wrapper .common-footer {
          padding: 0 20px; } }
