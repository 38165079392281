.contact-points__component {
  max-width: 880px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .contact-points__component .contact-item {
    width: 31%;
    padding: 30px;
    -webkit-box-shadow: 0px 4px 12px rgba(45, 45, 51, 0.12);
    -moz-box-shadow: 0px 4px 12px rgba(45, 45, 51, 0.12);
    box-shadow: 0px 4px 12px rgba(45, 45, 51, 0.12);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    border-radius: 16px; }
    .contact-points__component .contact-item .logo {
      width: 64px;
      min-width: 64px;
      height: 64px;
      margin: 0 auto 20px auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
      .contact-points__component .contact-item .logo.contact {
        background-image: url("./icons/ic_contact.svg"); }
      .contact-points__component .contact-item .logo.visit {
        background-image: url("./icons/ic_visit.svg"); }
      .contact-points__component .contact-item .logo.persollo {
        background-image: url("./icons/ic_persollo.svg"); }
    .contact-points__component .contact-item .title {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 500;
      text-align: center; }
    .contact-points__component .contact-item .divider {
      width: 100px;
      height: 1px;
      margin: 0 auto 20px auto;
      background-color: #FF385C; }
    .contact-points__component .contact-item .undertext {
      text-align: center; }
    @media only screen and (max-width: 768px) {
      .contact-points__component .contact-item {
        width: 100%;
        max-width: 400px;
        margin: 0 auto 20px auto; } }
  @media only screen and (max-width: 768px) {
    .contact-points__component {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; } }
