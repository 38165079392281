.main-page-layout__component .breadcrambs-block {
  border-bottom: 1px solid #E6E8EC; }
  .main-page-layout__component .breadcrambs-block .breadcrambs__component {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px 100px; }
    @media only screen and (max-width: 1024px) {
      .main-page-layout__component .breadcrambs-block .breadcrambs__component {
        padding: 20px; } }

.main-page-layout__component .content-block {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 100px; }
  @media only screen and (max-width: 1024px) {
    .main-page-layout__component .content-block {
      padding: 20px; } }
