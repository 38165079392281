.ui.button.button__component {
  height: 48px;
  margin: 0;
  padding: 0 30px;
  display: block;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .ui.button.button__component.small {
    height: 40px;
    font-size: 14px; }
  .ui.button.button__component.primary {
    background: #FF385C;
    color: #FFFFFF; }
    .ui.button.button__component.primary:hover {
      background: #3772FF; }
  .ui.button.button__component.primary-bordered {
    background: #FFFFFF;
    color: #FF385C;
    border: 2px solid #FF385C; }
  .ui.button.button__component.secondary {
    background: #FFFFFF;
    color: #23262F;
    border: 2px solid #E6E8EC; }
    .ui.button.button__component.secondary:hover {
      border: 2px solid #777E90; }
