.profile-settings-form__component {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .profile-settings-form__component > div {
    width: 50%; }
  .profile-settings-form__component .form-wrapper {
    margin-right: 30px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0; }
    .profile-settings-form__component .form-wrapper .hedera-account-info__component {
      margin-bottom: 20px; }
    .profile-settings-form__component .form-wrapper .profile-balance-info__component {
      margin-bottom: 20px; }
    .profile-settings-form__component .form-wrapper .email-wrapper {
      margin-bottom: 20px; }
    .profile-settings-form__component .form-wrapper .common-message__component {
      margin-top: 30px; }
    .profile-settings-form__component .form-wrapper .buttons-block {
      margin-top: 30px; }
    @media only screen and (max-width: 1024px) {
      .profile-settings-form__component .form-wrapper {
        width: 100%;
        margin-right: 0;
        -webkit-box-ordinal-group: 1;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1; } }
  .profile-settings-form__component .upload-wrapper {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
    .profile-settings-form__component .upload-wrapper .upload-profile-avatar__component {
      margin-bottom: 20px; }
    @media only screen and (max-width: 1024px) {
      .profile-settings-form__component .upload-wrapper {
        width: 100%;
        margin-bottom: 20px;
        -webkit-box-ordinal-group: 0;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0; } }
  @media only screen and (max-width: 1024px) {
    .profile-settings-form__component {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; } }
