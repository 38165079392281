.ui.dimmer.common-loader__component {
  background: #FFFFFF; }
  .ui.dimmer.common-loader__component .wrapper {
    padding: 20px; }
    .ui.dimmer.common-loader__component .wrapper .loader-logo {
      width: 50px;
      height: 56px;
      margin: 0 auto 20px auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("./images/gif_loader.gif"); }
    .ui.dimmer.common-loader__component .wrapper .text {
      font-size: 18px;
      color: #9757D7;
      line-height: 1.5; }
