.reset-password-form__component h1 {
  margin-bottom: 40px;
  font-weight: 600;
  line-height: 1;
  font-size: 36px;
  text-align: center; }

.reset-password-form__component .ui.form .field {
  margin-bottom: 30px; }

.reset-password-form__component .ui.form .button__component {
  width: 100%;
  margin-top: 30px; }

.reset-password-form__component .common-message__component {
  margin-top: 30px; }
