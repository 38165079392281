@import 'shared/styles/main.scss';

.upload-profile-banner__component {
  .title {
    margin-bottom: 16px;
    @include form-section-title-text;
  }

  .undertext {
    margin-bottom: 20px;
    @include regular-text;
    font-size: 12px;
  }

  .dropzone-area {
    height: 150px;
    background: $neutrals-7;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include border-radius(16px);
    @include display-flex;
    @include align-items-center;
    @include jc-center;

    .info-block {
      .icon {
        width: 48px;
        height: 48px;
        margin: 0 auto 10px auto;
        background-image: url("icons/ic_banner.svg");
        background-size: contain;
      }

      .text {
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
