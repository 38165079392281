.ui.modal.create-asset-success-modal__component .content .image {
  width: 250px;
  height: 250px;
  margin: 0 auto 30px auto;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  border-radius: 24px;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.ui.modal.create-asset-success-modal__component .content .undertext {
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
  word-wrap: break-word; }
  .ui.modal.create-asset-success-modal__component .content .undertext span {
    font-weight: 600; }

.ui.modal.create-asset-success-modal__component .content .buttons-block {
  width: 100%;
  max-width: 300px;
  margin: 0 auto; }
  .ui.modal.create-asset-success-modal__component .content .buttons-block a {
    display: block;
    margin-bottom: 10px; }
  .ui.modal.create-asset-success-modal__component .content .buttons-block .ui.button.button__component {
    width: 100%; }
