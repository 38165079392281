.collections-list__component .list-wrapper {
  display: -ms-grid;
  display: grid;
  grid-grap: 16px;
  -ms-grid-columns: minmax(0px, 1fr) [4];
  grid-template-columns: repeat(4, minmax(0px, 1fr)); }
  @media only screen and (max-width: 1280px) {
    .collections-list__component .list-wrapper {
      -ms-grid-columns: minmax(0px, 1fr) [3];
      grid-template-columns: repeat(3, minmax(0px, 1fr)); } }
  @media only screen and (max-width: 768px) {
    .collections-list__component .list-wrapper {
      -ms-grid-columns: minmax(0px, 1fr) [2];
      grid-template-columns: repeat(2, minmax(0px, 1fr)); } }
  @media only screen and (max-width: 500px) {
    .collections-list__component .list-wrapper {
      -ms-grid-columns: minmax(0px, 1fr) [1];
      grid-template-columns: repeat(1, minmax(0px, 1fr)); } }
