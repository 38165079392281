.single-asset-info-tab-content__component .blockchain-info {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .single-asset-info-tab-content__component .blockchain-info .icon {
    width: 30px;
    height: 30px;
    margin-right: 16px; }
  .single-asset-info-tab-content__component .blockchain-info .name {
    font-weight: 600; }

.single-asset-info-tab-content__component .description .label {
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
  color: #23262F;
  font-size: 18px;
  font-weight: 500; }

.single-asset-info-tab-content__component .description .text {
  font-family: "Poppins", sans-serif;
  color: #777E90;
  line-height: 1.6; }
