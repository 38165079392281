.ui.modal.common-modal__component {
  padding: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12); }
  .ui.modal.common-modal__component .close.icon {
    color: #FFFFFF; }
  .ui.modal.common-modal__component .header-block {
    padding: 10px 20px 0 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .ui.modal.common-modal__component .header-block .title {
      margin-right: 20px;
      font-family: "DM Sans", sans-serif;
      font-weight: 600;
      font-size: 18px; }
    .ui.modal.common-modal__component .header-block .close-button {
      width: 40px;
      min-width: 40px;
      height: 40px;
      cursor: pointer;
      background-image: url("icons/ic_close.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
