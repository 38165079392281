.get-in-touch-section__component {
  max-width: 880px;
  padding: 40px 30px;
  background: #FFFFFF;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 4px 12px rgba(45, 45, 51, 0.12);
  -moz-box-shadow: 0px 4px 12px rgba(45, 45, 51, 0.12);
  box-shadow: 0px 4px 12px rgba(45, 45, 51, 0.12); }
  .get-in-touch-section__component .logo {
    width: 64px;
    height: 64px;
    margin: 0 auto 20px auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./icons/ic_mail.svg"); }
  .get-in-touch-section__component h2 {
    margin-bottom: 30px;
    text-align: center;
    font-size: 32px; }
  .get-in-touch-section__component .undertext {
    max-width: 660px;
    margin: 0 auto 30px auto;
    text-align: center;
    color: #777E90; }
  .get-in-touch-section__component .buttons-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .get-in-touch-section__component .buttons-block .ui.button.button__component {
      width: 250px;
      margin: 0 5px; }
      .get-in-touch-section__component .buttons-block .ui.button.button__component:last-child {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center; }
        .get-in-touch-section__component .buttons-block .ui.button.button__component:last-child .icon {
          width: 16px;
          height: 16px;
          margin-left: 10px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("./icons/ic_copy.svg"); }
      @media only screen and (max-width: 768px) {
        .get-in-touch-section__component .buttons-block .ui.button.button__component {
          margin-bottom: 10px; } }
    @media only screen and (max-width: 768px) {
      .get-in-touch-section__component .buttons-block {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap; } }
