.profile-view__component .header-block {
  height: 200px;
  background: #F4F5F6;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  .profile-view__component .header-block .logo-container {
    width: 160px;
    height: 160px;
    padding: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -80px;
    background: #FFFFFF;
    overflow: hidden; }
    .profile-view__component .header-block .logo-container .common-logo-container__component {
      width: 100%;
      height: 100%; }

.profile-view__component .content-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 100px; }
  .profile-view__component .content-wrapper .profile-info__component {
    margin-bottom: 30px; }
  .profile-view__component .content-wrapper .social-icons-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .profile-view__component .content-wrapper .social-icons-wrapper .social-icon {
      margin-right: 5px; }
  .profile-view__component .content-wrapper .common-tab-list__component {
    margin-bottom: 20px; }
  .profile-view__component .content-wrapper .tabs-content-container {
    min-height: 280px; }
  @media only screen and (max-width: 1024px) {
    .profile-view__component .content-wrapper {
      padding: 20px; } }
