.login-form__component h1 {
  margin-bottom: 40px;
  font-weight: 600;
  line-height: 1;
  font-size: 36px;
  text-align: center; }

.login-form__component .form-wrapper .ui.form .field {
  margin-bottom: 30px; }

.login-form__component .form-wrapper .ui.form .forgot-password-link {
  display: block;
  text-align: center;
  text-decoration: underline; }

.login-form__component .form-wrapper .ui.form .button__component {
  width: 100%;
  margin-top: 30px; }

.login-form__component .form-wrapper .underform-text {
  margin-top: 20px;
  color: #777E90;
  text-align: center; }
  .login-form__component .form-wrapper .underform-text a {
    color: #FF385C; }

.login-form__component .form-wrapper .common-message__component {
  margin-top: 30px; }
