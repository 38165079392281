.ui.modal.moonpay-update-balance-modal__component .content {
  min-height: 300px; }
  .ui.modal.moonpay-update-balance-modal__component .content .loading-text {
    padding: 30px;
    text-align: center; }
  .ui.modal.moonpay-update-balance-modal__component .content .crypto-block {
    padding-top: 20px; }
    .ui.modal.moonpay-update-balance-modal__component .content .crypto-block .wallet-icon {
      width: 120px;
      height: 120px;
      margin: 0 auto 30px auto;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("./icons/ic_wallet.svg"); }
    .ui.modal.moonpay-update-balance-modal__component .content .crypto-block .infotext {
      margin-bottom: 30px;
      text-align: center;
      font-size: 16px;
      font-weight: 300; }
    .ui.modal.moonpay-update-balance-modal__component .content .crypto-block .profile-wallet__component {
      width: 100%;
      margin-bottom: 30px; }
    .ui.modal.moonpay-update-balance-modal__component .content .crypto-block .undertext {
      text-align: center;
      color: #777E90;
      font-weight: 500; }
