.pricing-page-content__component {
  color: #23262F; }
  .pricing-page-content__component .fees-items-wrapper {
    margin-top: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    @media screen and (max-width: 1200px) {
      .pricing-page-content__component .fees-items-wrapper {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center; } }
    .pricing-page-content__component .fees-items-wrapper .fees-item {
      width: 360px;
      height: 290px;
      margin-right: 20px;
      padding: 25px;
      background: #FFFFFF;
      border: 1px solid #F4F5F6;
      box-shadow: 0px 2px 20px rgba(20, 20, 43, 0.12);
      border-radius: 24px; }
      @media screen and (max-width: 1200px) {
        .pricing-page-content__component .fees-items-wrapper .fees-item {
          margin-top: 20px; } }
      .pricing-page-content__component .fees-items-wrapper .fees-item .item-title {
        height: 45px;
        text-align: center;
        margin-bottom: 40px; }
        .pricing-page-content__component .fees-items-wrapper .fees-item .item-title .title-text {
          font-weight: 600;
          font-size: 24px;
          text-align: center;
          color: #23262F; }
          @media screen and (max-width: 375px) {
            .pricing-page-content__component .fees-items-wrapper .fees-item .item-title .title-text {
              margin: auto 0;
              font-size: 20px; } }
        .pricing-page-content__component .fees-items-wrapper .fees-item .item-title .title-under {
          margin-top: 10px;
          font-weight: 400;
          font-size: 16px; }
          @media screen and (max-width: 375px) {
            .pricing-page-content__component .fees-items-wrapper .fees-item .item-title .title-under {
              font-size: 14px; } }
      .pricing-page-content__component .fees-items-wrapper .fees-item .first {
        padding-top: 20px; }
      .pricing-page-content__component .fees-items-wrapper .fees-item .dividing-line {
        margin: auto;
        width: 100px;
        height: 1px;
        background: #FF385C; }
      .pricing-page-content__component .fees-items-wrapper .fees-item .info-wrapper {
        margin-top: 30px;
        padding: 0 25px;
        text-align: center; }
        .pricing-page-content__component .fees-items-wrapper .fees-item .info-wrapper .first-item-text {
          font-weight: 600;
          font-size: 40px;
          line-height: 66px; }
          @media screen and (max-width: 375px) {
            .pricing-page-content__component .fees-items-wrapper .fees-item .info-wrapper .first-item-text {
              font-size: 28px; } }
        .pricing-page-content__component .fees-items-wrapper .fees-item .info-wrapper .first-item-text-under {
          font-weight: 400;
          font-size: 20px; }
        .pricing-page-content__component .fees-items-wrapper .fees-item .info-wrapper .list-item {
          margin-bottom: 15px;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row; }
          .pricing-page-content__component .fees-items-wrapper .fees-item .info-wrapper .list-item .icon {
            margin-right: 10px;
            margin-bottom: 5px; }
            .pricing-page-content__component .fees-items-wrapper .fees-item .info-wrapper .list-item .icon img {
              width: 22px; }
          .pricing-page-content__component .fees-items-wrapper .fees-item .info-wrapper .list-item .text {
            text-align: left;
            line-height: 21px; }
            @media screen and (max-width: 375px) {
              .pricing-page-content__component .fees-items-wrapper .fees-item .info-wrapper .list-item .text {
                font-size: 12px;
                line-height: 16px; } }
  .pricing-page-content__component .info-text-wrapper {
    margin-top: 50px;
    text-align: center; }
    .pricing-page-content__component .info-text-wrapper .text-with-star {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px; }
      @media screen and (max-width: 375px) {
        .pricing-page-content__component .info-text-wrapper .text-with-star {
          font-size: 14px;
          line-height: 16px; } }
    .pricing-page-content__component .info-text-wrapper .text {
      margin-top: 20px;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px; }
      @media screen and (max-width: 375px) {
        .pricing-page-content__component .info-text-wrapper .text {
          font-size: 28px; } }
