.text-field__component {
  width: 100%;
  margin: 0;
  position: relative; }
  .text-field__component .label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #B1B5C3; }
    .text-field__component .label span {
      font-weight: 400; }
  .text-field__component .errors {
    font-size: 12px;
    color: #FF385C;
    font-weight: 500;
    text-align: right; }
  .text-field__component .ui.input {
    border: 2px solid #F4F5F6;
    border-radius: 12px;
    overflow: hidden; }
    .text-field__component .ui.input .label {
      padding-top: 16px !important;
      padding-right: 0;
      font-weight: normal;
      background: none; }
    .text-field__component .ui.input input {
      width: 100%;
      height: 45px;
      padding-right: 20px !important;
      -webkit-border-radius: 12px !important;
      -moz-border-radius: 12px !important;
      -ms-border-radius: 12px !important;
      border-radius: 12px !important;
      border: none;
      font-family: "Poppins", sans-serif; }
    .text-field__component .ui.input input::-moz-placeholder {
      color: #c1c1c2; }
    .text-field__component .ui.input input::-webkit-input-placeholder {
      color: #c1c1c2; }
    .text-field__component .ui.input input:-ms-input-placeholder {
      color: #c1c1c2; }
    .text-field__component .ui.input input::-ms-input-placeholder {
      color: #c1c1c2; }
    .text-field__component .ui.input input::placeholder {
      color: #c1c1c2; }
    .text-field__component .ui.input input:-webkit-autofill,
    .text-field__component .ui.input input:-webkit-autofill:hover,
    .text-field__component .ui.input input:-webkit-autofill:focus,
    .text-field__component .ui.input input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #F4F5F6 inset !important; }
