.asset-details__component .asset-owner-actions__component {
  margin-bottom: 20px; }

.asset-details__component .main-content-block {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .asset-details__component .main-content-block .image-container {
    margin-right: 30px; }
    .asset-details__component .main-content-block .image-container .image {
      width: 500px;
      min-height: 300px;
      background-color: #F4F5F6;
      -webkit-border-radius: 24px;
      -moz-border-radius: 24px;
      -ms-border-radius: 24px;
      border-radius: 24px;
      overflow: hidden;
      box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .asset-details__component .main-content-block .image-container .image img {
        max-width: 100%;
        display: block; }
      @media only screen and (max-width: 550px) {
        .asset-details__component .main-content-block .image-container .image {
          width: 100%; } }
    @media only screen and (max-width: 1024px) {
      .asset-details__component .main-content-block .image-container {
        margin: 0 auto 20px auto; } }
  .asset-details__component .main-content-block .info-conteiner {
    width: 100%; }
    .asset-details__component .main-content-block .info-conteiner .asset-details-info__component {
      margin-bottom: 30px; }
  @media only screen and (max-width: 1024px) {
    .asset-details__component .main-content-block {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; } }
