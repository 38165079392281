.upload-collection-logo__component .title {
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  color: #23262F;
  font-size: 18px;
  font-weight: 500; }

.upload-collection-logo__component .undertext {
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  color: #777E90;
  line-height: 1.6;
  font-size: 12px; }

.upload-collection-logo__component .dropzone-container {
  width: 160px;
  height: 160px;
  padding: 5px;
  border: 2px dashed #E6E8EC;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden; }
  .upload-collection-logo__component .dropzone-container .dropzone-area {
    width: 100%;
    height: 100%;
    background: #F4F5F6;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .upload-collection-logo__component .dropzone-container .dropzone-area .info-block .icon {
      width: 48px;
      height: 48px;
      margin: 0 auto;
      background-image: url("icons/ic_banner.svg");
      background-size: contain; }
