.create-asset-form__component {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .create-asset-form__component .form-wrapper {
    width: 50%;
    margin-right: 30px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0; }
    .create-asset-form__component .form-wrapper .fee-text {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 16px; }
    .create-asset-form__component .form-wrapper .checkbox-container {
      margin-bottom: 30px; }
      .create-asset-form__component .form-wrapper .checkbox-container label {
        color: #777E90; }
    .create-asset-form__component .form-wrapper .button-block {
      margin-top: 30px; }
    .create-asset-form__component .form-wrapper .create-asset-balance-message__component {
      margin-top: 30px; }
    .create-asset-form__component .form-wrapper .add-hbar-button {
      font-weight: 500;
      cursor: pointer;
      text-decoration: underline; }
    @media only screen and (max-width: 768px) {
      .create-asset-form__component .form-wrapper {
        width: 100%;
        margin-right: 0;
        -webkit-box-ordinal-group: 1;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1; } }
  .create-asset-form__component .upload-wrapper {
    width: 50%;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
    @media only screen and (max-width: 768px) {
      .create-asset-form__component .upload-wrapper {
        width: 100%;
        margin-bottom: 20px;
        -webkit-box-ordinal-group: 0;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0; } }
  @media only screen and (max-width: 768px) {
    .create-asset-form__component {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; } }
