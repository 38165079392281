.no-items-placeholder__component {
  width: 100%;
  padding: 20px 0; }
  .no-items-placeholder__component .image {
    width: 180px;
    height: 180px;
    margin: 0 auto;
    background-image: url("./images/ic_no_items_placeholder.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .no-items-placeholder__component .title {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 300; }
  .no-items-placeholder__component .text {
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
    color: #777E90;
    line-height: 1.6;
    text-align: center;
    color: #777E90; }
