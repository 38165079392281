.common-logo-container__component {
  width: 40px;
  min-width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("icons/ic_default.svg");
  border: 1px solid #E6E8EC; }
  .common-logo-container__component.collection {
    background-image: url("icons/ic_default_collection.svg"); }
