.main-landing-page-content__component .section .section-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 100px; }
  @media only screen and (max-width: 1024px) {
    .main-landing-page-content__component .section .section-wrapper {
      padding: 20px; } }

.main-landing-page-content__component .section.header {
  width: 100%;
  height: 700px;
  background-image: url("images/img_header_section.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .main-landing-page-content__component .section.header .section-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    .main-landing-page-content__component .section.header .section-wrapper .text-container {
      width: 50%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .main-landing-page-content__component .section.header .section-wrapper .text-container .wrapper {
        color: #FFFFFF;
        font-family: "DM Sans", sans-serif; }
        .main-landing-page-content__component .section.header .section-wrapper .text-container .wrapper .title-text {
          margin-bottom: 30px;
          font-weight: 600;
          font-size: 50px;
          line-height: 1.2; }
          @media only screen and (max-width: 1024px) {
            .main-landing-page-content__component .section.header .section-wrapper .text-container .wrapper .title-text {
              font-size: 36px; } }
          @media only screen and (max-width: 500px) {
            .main-landing-page-content__component .section.header .section-wrapper .text-container .wrapper .title-text {
              font-size: 30px;
              text-align: center; } }
        .main-landing-page-content__component .section.header .section-wrapper .text-container .wrapper .undertext {
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 1.5; }
          @media only screen and (max-width: 768px) {
            .main-landing-page-content__component .section.header .section-wrapper .text-container .wrapper .undertext {
              display: none; } }
        .main-landing-page-content__component .section.header .section-wrapper .text-container .wrapper .buttons-block {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex; }
          .main-landing-page-content__component .section.header .section-wrapper .text-container .wrapper .buttons-block a .create {
            margin-left: 10px;
            background: inherit;
            color: #FFFFFF; }
            .main-landing-page-content__component .section.header .section-wrapper .text-container .wrapper .buttons-block a .create:hover {
              border-color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .main-landing-page-content__component .section.header .section-wrapper .text-container {
          width: 100%; } }
    .main-landing-page-content__component .section.header .section-wrapper .image-container {
      width: 50%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
      @media only screen and (max-width: 768px) {
        .main-landing-page-content__component .section.header .section-wrapper .image-container {
          width: 100%; } }
      .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel {
        width: 100% !important;
        height: 476px !important; }
        @media only screen and (max-width: 1024px) {
          .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel {
            width: 450px !important;
            height: 350px !important;
            margin: 0 auto; } }
        @media only screen and (max-width: 992px) {
          .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel {
            width: 390px !important;
            height: 305px !important;
            margin: 0 auto; } }
        @media only screen and (max-width: 768px) {
          .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel {
            width: 500px !important;
            height: 400px !important;
            margin: 0 auto; } }
        @media only screen and (max-width: 500px) {
          .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel {
            width: 355px !important;
            height: 305px !important; } }
        @media only screen and (max-width: 375px) {
          .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel {
            width: 295px !important;
            height: 250px !important;
            margin: 0 auto; } }
        .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel .image {
          width: 560px;
          border-radius: 15px; }
          @media only screen and (max-width: 768px) {
            .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel .image {
              width: 400px !important;
              height: 400px !important; } }
          @media only screen and (max-width: 500px) {
            .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel .image {
              width: 300px !important;
              height: 300px !important; } }
          @media only screen and (max-width: 375px) {
            .main-landing-page-content__component .section.header .section-wrapper .image-container .common-nft-slider__component .react-3d-carousel .image {
              width: 200px !important;
              height: 250px !important; } }
    @media only screen and (max-width: 768px) {
      .main-landing-page-content__component .section.header .section-wrapper {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap; } }
  @media only screen and (max-width: 1024px) {
    .main-landing-page-content__component .section.header {
      height: 600px; } }
  @media only screen and (max-width: 768px) {
    .main-landing-page-content__component .section.header {
      height: 700px; } }
  @media only screen and (max-width: 500px) {
    .main-landing-page-content__component .section.header {
      height: 600px; } }

.main-landing-page-content__component .section.description {
  padding-top: 50px;
  padding-bottom: 50px; }
  .main-landing-page-content__component .section.description .section-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .main-landing-page-content__component .section.description .section-wrapper .section {
      width: 30%; }
      .main-landing-page-content__component .section.description .section-wrapper .section.title {
        font-weight: 500;
        font-size: 24px;
        line-height: 1.3; }
      .main-landing-page-content__component .section.description .section-wrapper .section.desc .title {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center; }
        .main-landing-page-content__component .section.description .section-wrapper .section.desc .title .icon {
          width: 36px;
          min-width: 36px;
          height: 36px;
          margin-right: 16px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat; }
          .main-landing-page-content__component .section.description .section-wrapper .section.desc .title .icon.wallet {
            background-image: url("./icons/ic_wallet.svg"); }
          .main-landing-page-content__component .section.description .section-wrapper .section.desc .title .icon.graph {
            background-image: url("./icons/ic_graph.svg"); }
        .main-landing-page-content__component .section.description .section-wrapper .section.desc .title .txt {
          font-weight: 500;
          font-size: 20px; }
      .main-landing-page-content__component .section.description .section-wrapper .section.desc .text {
        padding-left: 54px;
        color: #777E90; }
      @media only screen and (max-width: 768px) {
        .main-landing-page-content__component .section.description .section-wrapper .section {
          width: 100%;
          margin-bottom: 20px; }
          .main-landing-page-content__component .section.description .section-wrapper .section:last-child {
            margin-bottom: 0; } }
    @media only screen and (max-width: 768px) {
      .main-landing-page-content__component .section.description .section-wrapper {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap; } }
  @media only screen and (max-width: 768px) {
    .main-landing-page-content__component .section.description {
      padding-top: 20px;
      padding-bottom: 20px; } }
